import React from 'react';
import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';

const StyledContainerBox = styled(Box)`
    margin: 40px auto;
    width: 100%;
`;

const SectionFull = ({ children }) => {
    return <StyledContainerBox>{children}</StyledContainerBox>;
};

export default SectionFull;